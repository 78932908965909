import React from 'react'
import Img from 'gatsby-image'
import ReactMarkdown from 'react-markdown'

import { Rating } from '../Booklist/Modal/style'
import { Box, Body, Stack } from 'components'
import { TestimonialType } from 'models'

export const Testimonial = ({
  name,
  image,
  rating,
  testimonial
}: TestimonialType) => {
  return (
    <Box p="2rem" elevation={8} rounded>
      <Stack gridTemplateColumns="min-content 1fr" mb="1rem">
        <Img fluid={image.fluid} style={{ height: '24px', width: '24px' }} />
        <Body m={0} color="greys.grey90">
          {name}
        </Body>
      </Stack>
      <Rating readOnly defaultValue={rating} size="small" />
      <ReactMarkdown source={testimonial.testimonial} />
    </Box>
  )
}

export default Testimonial
