import React from 'react'
import ReactMarkdown from 'react-markdown'
import { FiX } from 'react-icons/fi'

import { Stack, Heading, Button } from 'components'
import { Overlay, Box, OverlayColor, Rating, ExitButtonWrapper } from './style'
import { Cover, CoverImg } from 'components/BookList/style'
import { BookType } from '..'
import { generateRating } from 'utils'

type Props = {
  book: BookType
  setActiveBook: (_: null) => void
}

export const Modal = ({ book, setActiveBook }: Props) => {
  return (
    <Overlay>
      <OverlayColor />
      <Box data-testid="book-modal">
        <ExitButtonWrapper>
          <Button
            variant="icon"
            data-testid="close-modal-btn"
            onClick={() => setActiveBook(null)}
          >
            <FiX />
          </Button>
        </ExitButtonWrapper>
        <Stack gridTemplateColumns="0.5fr 1fr" gridColumnGap="3rem">
          <Cover>
            <CoverImg fluid={book.cover.fluid} />
          </Cover>

          <div style={{ overflow: 'scroll', maxHeight: '450px' }}>
            <Heading variant="sm" mb="1rem">
              {book.title}
            </Heading>
            {book.reviews && (
              <Stack
                mb="1rem"
                gridTemplateColumns="min-content max-content"
                alignContent="center"
              >
                <Rating
                  name="half-rating"
                  readOnly
                  size="small"
                  defaultValue={generateRating(book.reviews)}
                  precision={0.25}
                />
                <Heading variant="xs" m={0} mt={'-6px'}>
                  {generateRating(book.reviews)}
                </Heading>
              </Stack>
            )}
            <ReactMarkdown source={book.description.description} />
          </div>
        </Stack>
      </Box>
    </Overlay>
  )
}

export default Modal
