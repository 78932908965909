import React, { useState } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { Spring, animated, config } from 'react-spring/renderprops.cjs'
import { FluidObject } from 'gatsby-image'
import VisibilitySensor from 'react-visibility-sensor'

import { Stack, RatingBadge, Body } from 'components'
import { ReviewType } from 'models'
import { Cover, CoverImg, Book, List } from 'components/BookList/style'
import { Modal } from './Modal/Modal'

export type BookType = {
  title: string
  description: {
    description: string
  }
  reviews: ReviewType[]
  cover: FluidObject
}

export const BookList = () => {
  const data = useStaticQuery(graphql`
    query {
      allContentfulBook {
        nodes {
          title
          contentful_id
          description {
            description
          }
          reviews {
            review {
              review
            }
            rating
          }
          cover {
            fluid {
              ...GatsbyContentfulFluid_withWebp
            }
          }
        }
      }
    }
  `)
  const [activeBook, setActiveBook] = useState<null | BookType>(null)

  return (
    <>
      <List>
        {data.allContentfulBook.nodes.map(book => {
          return (
            <VisibilitySensor
              key={book.contentful_id}
              partialVisibility
              offset={{ bottom: -400 }}
            >
              {({ isVisible }) => (
                <Spring
                  native
                  config={config.molasses}
                  delay={200}
                  to={{
                    opacity: isVisible ? 1 : 0,
                    transform: isVisible ? 'translateY(0)' : 'translateY(200px)'
                  }}
                >
                  {props => (
                    <animated.div style={props}>
                      <Book
                        data-testid="book"
                        key={book.contentful_id}
                        onClick={() => setActiveBook(book)}
                      >
                        <Cover>
                          <CoverImg fluid={book.cover.fluid} />
                        </Cover>
                        <Stack alignItems="center" mt={3}>
                          <Stack
                            gridTemplateColumns="1fr min-content"
                            alignItems="start"
                          >
                            <Body m={0}>
                              <strong>{book.title}</strong>
                            </Body>
                            {book.reviews && (
                              <RatingBadge
                                variant="thin"
                                reviews={book.reviews}
                              />
                            )}
                          </Stack>
                        </Stack>
                      </Book>
                    </animated.div>
                  )}
                </Spring>
              )}
            </VisibilitySensor>
          )
        })}
      </List>
      {activeBook && <Modal book={activeBook} setActiveBook={setActiveBook} />}
    </>
  )
}

export default BookList
