import React from 'react'
import { Body, Heading, Stack } from 'components'

type Props = {
  number: number
  label: string
}
export const Statistic = ({ number, label }: Props) => {
  return (
    <Stack gridRowGap="0">
      <Heading textAlign="center" m={0} variant="sm">
        {number}
      </Heading>
      <Body textAlign="center" m={0}>
        {label}
      </Body>
    </Stack>
  )
}

export default Statistic
