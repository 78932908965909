import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { isMobile } from 'react-device-detect'
import { Parallax, ParallaxProvider } from 'react-scroll-parallax'
import Masonry from 'react-masonry-css'
import VisibilitySensor from 'react-visibility-sensor'
import { Spring, animated, config } from 'react-spring/renderprops.cjs'

import './style.css'
import {
  Body,
  Heading,
  Container,
  PricingTier,
  Section,
  Stack,
  Button
} from 'components'
import { TestimonialType } from 'models'
import { Statistic, BookList, Testimonial } from './components'
import {
  HeaderScreenshot,
  MobileScreenshot,
  ScreenshotOne,
  ScreenshotTwo
} from './style'
import { theme } from 'style'
import Blob from './images/blob.svg'
import { generateSubscriptionExpiry } from 'utils'

export const Page = () => {
  const data = useStaticQuery(graphql`
    query {
      allContentfulAsset(
        filter: {
          contentful_id: {
            in: [
              "poITr1bSIKaBzBiuSmrGl"
              "4gogQyxSRngX269sMbF3mw"
              "63DebfWhwAwJPFvEOTYKYS"
              "x7lZsZYCMQV9QGrEqqplZ"
            ]
          }
        }
      ) {
        nodes {
          contentful_id
          file {
            url
          }
        }
      }
      allContentfulTestimonial {
        nodes {
          contentful_id
          name
          rating
          testimonial {
            testimonial
          }
          image {
            fluid {
              ...GatsbyContentfulFluid_withWebp
            }
          }
        }
      }
    }
  `)

  return (
    <ParallaxProvider>
      <Section
        backgroundColor="#F8F7F2"
        p={0}
        pt={100}
        height="100vh"
        style={{
          display: 'flex',
          alignItems: 'center'
        }}
      >
        <img
          src={Blob}
          style={{ position: 'absolute', top: 0, right: 0, zIndex: 0 }}
        />
        <Container>
          <Stack
            gridTemplateColumns={['1fr', 'repeat(2, 1fr)']}
            gridColumnGap={[0, '5rem']}
            gridRowGap={['2rem', 0]}
            alignContent="center"
          >
            <div style={{ position: 'relative' }}>
              <Heading mb="1rem" color={theme.colors.greys.grey70}>
                The one-stop-shop you need for quality English resources.
              </Heading>
              <Body mb="2rem">
                Get access anytime, anywhere to all LSG study guides in 2021.
              </Body>
              <Button href="#pricing-tier">Buy Now</Button>
            </div>

            <div>
              <HeaderScreenshot
                src={
                  data.allContentfulAsset.nodes.find(
                    asset => asset.contentful_id === 'poITr1bSIKaBzBiuSmrGl'
                  ).file.url
                }
              />

              <Parallax y={[!isMobile ? 40 : 0, -40]} x={[5, 5]}>
                <MobileScreenshot
                  src={
                    data.allContentfulAsset.nodes.find(
                      asset => asset.contentful_id === '4gogQyxSRngX269sMbF3mw'
                    ).file.url
                  }
                />
              </Parallax>
            </div>
          </Stack>
        </Container>
      </Section>
      <Section>
        <Container>
          <Heading
            color={theme.colors.greys.grey70}
            textAlign="center"
            mb="1rem"
          >
            Get access to all current and upcoming LSG study guides
          </Heading>
          <Body textAlign="center" mb="2.5rem">
            With new advice added each week, we've got your back 👊
          </Body>
          <Container maxWidth="md">
            <BookList />
          </Container>
        </Container>
      </Section>
      <Section>
        <Container maxWidth="sm">
          <Heading
            color={theme.colors.greys.grey70}
            textAlign="center"
            mb="1rem"
          >
            🚀 Boost your ATAR marks with LSG, the leading English specialists
          </Heading>
        </Container>
        <Container>
          <Stack gridTemplateColumns={['0 1fr 0', 'repeat(3,1fr)']}>
            <div />
            <PricingTier />
            <div />
          </Stack>
        </Container>
      </Section>

      <Section>
        <Container>
          <Stack
            gridTemplateColumns={['1fr', 'repeat(2,1fr)']}
            gridTemplateRows={['repeat(2,1fr)', '1fr']}
            alignItems="center"
            gridGap="2rem"
          >
            <div style={{ position: 'relative' }}>
              <Parallax y={[60, 0]} x={[5, 5]}>
                <ScreenshotTwo
                  src={data.allContentfulAsset.nodes[2].file.url}
                />
              </Parallax>
              <Parallax y={[-60, 0]} x={[-5, -5]}>
                <ScreenshotOne
                  src={
                    data.allContentfulAsset.nodes.find(
                      asset => asset.contentful_id === 'x7lZsZYCMQV9QGrEqqplZ'
                    ).file.url
                  }
                />
              </Parallax>
            </div>

            <div>
              <Heading variant="sm" color={theme.colors.greys.grey70}>
                Everything you need in one place
              </Heading>
              <Body>
                Use what’s relevant for you, and study at your own pace.
              </Body>
              <Body>To keep you covered, we've got:</Body>
              <Stack gridTemplateColumns="repeat(3,1fr)">
                <Statistic
                  number="20+"
                  label="digital guides, updated almost daily"
                />
                <Statistic
                  number={10}
                  label="hours of videos (visual learners, we got you)"
                />
                <Statistic
                  number="100+"
                  label="A+ example essays with detailed breakdowns"
                />
              </Stack>
            </div>
          </Stack>
        </Container>
      </Section>
      <Section>
        <Container maxWidth="md">
          <Heading
            textAlign="center"
            mb="2rem"
            color={theme.colors.greys.grey70}
          >
            What our customers say
          </Heading>

          <Masonry
            breakpointCols={!isMobile ? 3 : 1}
            className="my-masonry-grid"
            columnClassName="my-masonry-grid_column"
          >
            {data.allContentfulTestimonial.nodes
              .sort(node => node.order)
              .map((testimonial: TestimonialType) => (
                <VisibilitySensor
                  key={testimonial.contentful_id}
                  partialVisibility
                  offset={{ bottom: -400 }}
                >
                  {({ isVisible }) => (
                    <Spring
                      native
                      config={config.molasses}
                      delay={200}
                      to={{
                        opacity: isVisible ? 1 : 0,
                        transform: isVisible
                          ? 'translateY(0)'
                          : 'translateY(200px)'
                      }}
                    >
                      {props => (
                        <animated.div style={props}>
                          <Testimonial
                            contentful_id={testimonial.contentful_id}
                            key={testimonial.contentful_id}
                            name={testimonial.name}
                            rating={testimonial.rating}
                            order={testimonial.order}
                            image={testimonial.image}
                            testimonial={testimonial.testimonial}
                          />
                        </animated.div>
                      )}
                    </Spring>
                  )}
                </VisibilitySensor>
              ))}
          </Masonry>
        </Container>
      </Section>
      <Section>
        <Container maxWidth="sm">
          <Heading textAlign="center" mb="1.5rem" variant="sm">
            FAQ
          </Heading>
          <Heading
            variant="xs"
            mb="0.25rem"
            fontWeight="bold"
            color={theme.colors.greys.grey70}
          >
            Can I download or get a PDF version of these study guides?
          </Heading>
          <Body mb="2rem">
            No, sorry! This is because our study guides contain bonus videos and
            links to additional online resources, meaning that a hard copy just
            wouldn't do the study guide justice! We also regularly update the
            study guides in accordance with the latest VCAA requirements. You
            can only get the full LSG learning experience online.
          </Body>
          <Heading
            variant="xs"
            mb="0.25rem"
            fontWeight="bold"
            color={theme.colors.greys.grey70}
          >
            I’ve purchased other study guides before, can I pay the difference
            and get access to this limited offer?
          </Heading>
          <Body>
            Yes, you’re more than welcome to. Start a chat with us and we’ll get
            you sorted :)
          </Body>
        </Container>
      </Section>
      <Section>
        <Container>
          <Heading color={theme.colors.greys.grey70} textAlign="center">
            The sooner you buy, the more bang for your buck 💸
          </Heading>
          <Stack gridTemplateColumns={['0 1fr 0', 'repeat(3,1fr)']}>
            <div />
            <PricingTier />
            <div />
          </Stack>
        </Container>
      </Section>
    </ParallaxProvider>
  )
}

export default Page
