import styled from 'styled-components'
import { theme } from 'style'
import media from 'styled-media-query'

type ScreenShotProps = {
  src: string
}

export const HeaderScreenshot = styled.div<ScreenShotProps>`
  height: 65vh;
  width: 49%;
  border-radius: 10px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-size: contain;
  background-color: white;
  background-repeat: no-repeat;
  background-image: url(${props => props.src});
  box-shadow: ${theme.shadows[4]};
  ${media.lessThan('small')`
    position: relative;
    width: 100%
    height: 250px
	`}
`

export const MobileScreenshot = styled.div<ScreenShotProps>`
  border-radius: 10px;
  height: 65vh;
  width: 320px;
  left: 0;
  background-size: contain;
  background-color: white;
  background-repeat: no-repeat;
  background-image: url(${props => props.src});
  box-shadow: ${theme.shadows[8]};
  ${media.lessThan('small')`
    position: relative;
    width: 150px;
    height: 250px;
	`}
`

export const Screenshot = styled.div<ScreenShotProps>`
  height: 280px;
  width: 95%;
  background-size: contain;
  background-position: center;
  border-radius: 10px;
  /* position: absolute; */
  background-color: white;
  background-repeat: no-repeat;
  background-image: url(${props => props.src});
  ${media.lessThan('small')`
    height: 180px
  `}
`

export const ScreenshotOne = styled(Screenshot)`
  box-shadow: ${theme.shadows[8]};
  bottom: 0;
  z-index: 2;
  right: 0;
`
export const ScreenshotTwo = styled(Screenshot)`
  box-shadow: ${theme.shadows[4]};
  top: 0;
  left: 0;
`
